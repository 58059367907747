import { defineComponent, ref, watchEffect, watch } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "MainHead",
  setup: function setup() {
    var active = ref(1);
    var router = useRouter();
    console.log("-------", router.currentRoute.value);
    watch(function () {
      return router.currentRoute.value.path;
    }, function (toPath) {
      if (toPath == '/homenew') {
        active.value = 1;
      } else if (toPath == '/news') {
        active.value = 3;
      } else {
        active.value = 2;
      }
    }, {
      immediate: true,
      deep: true
    });

    var handleHome = function handleHome() {
      active.value = 1;
      router.push('/homenew');
    };

    var handleIntriduction = function handleIntriduction() {
      active.value = 2;
      router.push('/introduction'); // console.log(active.value);
    };

    var hanldeNews = function hanldeNews() {
      active.value = 3;
      router.push('/news');
    };

    return {
      active: active,
      handleHome: handleHome,
      handleIntriduction: handleIntriduction,
      hanldeNews: hanldeNews
    };
  }
});