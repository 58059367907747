import { createApp } from "vue";
import App from "./App.vue";
import register from "./utils/register";
import router from "./route";
import "lib-flexible";
import Meta from 'vue-meta';
const app = createApp(App);
register(app);

app.use(router);


// app.use(Meta)

app.mount("#app");


