import { defineComponent } from "vue";
export default defineComponent({
  name: "MainFoot",
  setup: function setup() {
    var gotoBaicheng = function gotoBaicheng() {
      window.open("https://www.bcepd.cn/");
    };

    var gotoHuiben = function gotoHuiben() {
      window.open("https://bcpicbook.yuedu365.cn");
    };

    var gotoTM = function gotoTM() {
      window.open("https://shop257782356.taobao.com/category-1431690426.htm?spm=a1z10.1-c-s.w5002-21359729508.3.58d53ae8y0zaI1&search=y&catName=%BB%E6%B1%BE%CD%A8%B5%E7%D7%D3%D4%C4%B6%C1%C6%F7");
    };

    var gotoJD = function gotoJD() {
      window.open("https://mall.jd.com/index-11675343.html?from=pc");
    };

    return {
      gotoBaicheng: gotoBaicheng,
      gotoHuiben: gotoHuiben,
      gotoTM: gotoTM,
      gotoJD: gotoJD
    };
  }
});